import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { Link } from "../components/link";
import SliderRisorse from "../components/slider-risorse";
import BoxWebinar from "../components/box-webinar";
import BoxPost from "../components/box-post";

import Half8Solid from "../icons/half-8-solid.svg";
import Half2Solid from "../icons/half-2-solid.svg";

const Risorse = ({ data }) => {
  const { posts, webinar } = data;

  const allPost = posts.nodes;
  const allWebinar = webinar.nodes;

  return (
    <Layout>
      <Seo title={`Risorse`} description={``} />

      <div className="hero">
        <div className="container-lg position-relative">
          <Half2Solid className="ico-svg ico-half-2-solid ico-right" />
          <Half8Solid className="ico-svg ico-half-8-solid ico-left" />
          <div className="row">
            <div className="col-10 col-md-10 col-lg-8">
              <h1 className="hero__title">
                <strong>Risorse</strong>
                <br />
                Capire, imparare, risolvere e ispirarsi con Intranet 80.20.
              </h1>
            </div>
          </div>
        </div>
        <div className="hero__text-scroll hero__text-scroll--classic">
          <div className="text-scroll" aria-hidden="true">
            <div className="text-scroll__wrap">
              <span className="text-uppercase risorse">Risorse</span>
            </div>
            <div className="text-scroll__wrap text-scroll__wrap--repeat">
              <span className="text-uppercase risorse">Risorse</span>
            </div>
          </div>
        </div>
      </div>

      <section id="blog" className="section section--risorse">
        <div className="container-lg">
          <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-between align-items-center mb-4">
                <h2 className="mb-0">Blog</h2>
                <Link to="/blog/" title="Vedi tutti i post" className="btn-link">
                  <span>Vedi tutti</span>
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <SliderRisorse items={allPost} component={BoxPost} />
            </div>
          </div>
        </div>
      </section>

      <section id="webinar" className="section section--dark section--risorse">
        <div className="container-lg">
          <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-between align-items-center mb-4">
                <h2 className="mb-0">Webinar</h2>
                <Link to="/webinar/" title="Vedi tutti i webinar" className="btn-link">
                  <span>Vedi tutti</span>
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <SliderRisorse items={allWebinar} component={BoxWebinar} />
            </div>
          </div>
        </div>
      </section>

      {/* <section id="pillole" className="section section--risorse">
        <div className="container-lg">
          <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-between align-items-center mb-4">
                <h2 className="mb-0">Pillole</h2>
                <Link to="/blog/" title="Vedi tutte le pillole" className="btn-link">
                  <span>Vedi tutti</span>
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <SliderRisorse items={allWebinar} component={BoxPost} />
            </div>
          </div>
        </div>
      </section> */}
    </Layout>
  );
};

export default Risorse;

export const query = graphql`
  query {
    posts: allContentfulPost(limit: 9, sort: { fields: date, order: DESC }) {
      nodes {
        title
        date(formatString: "DD MMMM YYYY", locale: "it")
        category
        image {
          title
          description
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
        link
      }
    }
    webinar: allContentfulWebinar(limit: 9, sort: { fields: date, order: DESC }) {
      nodes {
        title
        date(formatString: "DD MMMM YYYY", locale: "it")
        category
        image {
          title
          description
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
        link
      }
    }
  }
`;
