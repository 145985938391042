import React from "react";
import Slider from "react-slick";

const settings = {
  arrows: false,
  dots: true,
  infinite: false,
  swipe: true,
  fade: false,
  slidesToShow: 3,
  slidesToScroll: 3,
  responsive: [
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const SliderRisorse = ({ items, component: Component }) => {
  return (
    <Slider {...settings} className="slider-risorse">
      {items.slice(0, 10).map((item, i) => (
        <div key={i}>
          <Component data={item} />
        </div>
      ))}
    </Slider>
  );
};

export default SliderRisorse;
